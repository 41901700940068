import React, { useEffect } from "react";

import Icon from "../../assets/images/Logo/Logo.png";
import { Col, Row } from "react-bootstrap";
import {
  EmailIcon,
  FacebookIcon,
  InstaIcon,
  PhoneIcon,
  TweeterIcon,
  YoytubeIcon,
} from "../../utils/svgicons";
import { useNavigate, useLocation } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    let path = location.pathname.split("");

    if (path?.length > 1) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const handleHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="page-fotter container">
        <Row>
          <Col md={4} className="d-flex justify-content-center">
            <div className="brand-col">
              <div className="d-flex brand-name">
                <img src={Icon} alt="" />
              </div>
              <div className="intro mt-4">
                Arcu at dictum sapien, mollis. Vulputate sit id accumsan,
                ultricies. In ultrices malesuada elit mauris.
              </div>
            </div>
          </Col>
          <Col md={2}>
            <div className="links">
              <h1>Links</h1>
              <ul>
                <li className="cursor" onClick={handleHome}>
                  Home
                </li>
                <li className="cursor" onClick={() => navigate(`/about-us`)}>
                  About us
                </li>
                <li className="cursor" onClick={() => navigate(`/#feature`)}>
                  Features
                </li>
              </ul>
            </div>
          </Col>
          <Col md={2}>
            <div className="links">
              <h1>Legal</h1>
              <ul>
                <li
                  className="link-default cursor"
                  onClick={() => navigate("/terms-use")}
                >
                  Terms of Use
                </li>
                <li
                  className="link-default cursor"
                  onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Policy
                </li>
                <li
                  className="link-default cursor"
                  onClick={() => navigate("/cookie-policy")}
                >
                  Cookie Policy
                </li>
              </ul>
            </div>
          </Col>
          <Col md={4} className="d-flex justify-content-center">
            <div className="contact-us">
              <h1>Contact Us</h1>
              <div className="contact-us-content d-flex">
                <EmailIcon />
                <span>info@rolidex.com</span>
              </div>
              <div className="contact-us-content d-flex">
                <PhoneIcon />
                <span>+1 234 456 678 89</span>
              </div>
            </div>
          </Col>
        </Row>
        <div className="horizontol-line mt-5"></div>
        <Row className="mt-3 d-none d-lg-flex">
          <Col className="copy-right d-flex">
            <p>Copyright 2024 Motor Tribe, all rights reserved</p>
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="social-media d-flex">
              <FacebookIcon />
              <TweeterIcon />
              <InstaIcon />
              <YoytubeIcon />
            </div>
          </Col>
        </Row>
        <div className="tablet-social d-lg-none d-flex">
          <div className="copy-right-tablet d-flex">
            <p>Copyright 2024 Motor Tribe, all rights reserved</p>
          </div>
          <div className="d-flex">
            <div className="social-media-tablet d-flex">
              <FacebookIcon />
              <TweeterIcon />
              <InstaIcon />
              <YoytubeIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Footer };
