import React from "react";
import { CenterMode } from "../components/Defaut/SliderOurService";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testing = () => {
  return (
    <>
      <CenterMode />
    </>
  );
};

export { Testing };
