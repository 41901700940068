import React, { forwardRef } from 'react'
import { Form } from 'react-bootstrap'

const CustomInput = forwardRef((props, ref) => {
    const {
        field,
        autoFocus = false,
        placeholder = "",
        type = "text",
        minLength = 0,
        maxLength = 255,
        value = "",
        onChange,
        disabled = false,
        className = ''
    } = props

    return (
        <Form.Control
            {...field}
            type={type}
            autoComplete="off"
            placeholder={placeholder}
            minLength={minLength}
            maxLength={maxLength}
            value={value}
            className={className}
            disabled={disabled}
            onChange={(value) => onChange(value)}
            autoFocus={autoFocus}
        />
    )
})

export default CustomInput