import React from "react";
import { Col, Row } from "react-bootstrap";

import Apple from "../../assets/images/Home/social media/Apple.png";
import Android from "../../assets/images/Home/social media/Android.png";
import RightApple from "../../assets/images/Home/social media/R - Apple.png";
import RightAndroid from "../../assets/images/Home/social media/R - And.png";

const SocialMedia = () => {
  return (
    <>
      <div className="social-media-component container">
        <Row>
          <Col className="join-us-detail" xs={12} md={6} s>
            <div className="text">
              <h1>
                Join the social media revolution. <br />
                Download <span>Insight</span> now and get 50 points!
              </h1>
              <p className="mt-2">
                Cum et convallis risus placerat aliquam, nunc. Scelerisque
                aliquet faucibus tincidunt eu adipiscing sociis arcu lorem
                porttitor.
              </p>
            </div>
            <div className="os-type mt-2">
              <img src={Apple} className="apple" alt=""/>
              <img src={Android} alt=""/>
            </div>
          </Col>
          <Col className="types" xs={12} md={{ span: 5, offset: 1 }}>
            <div className="os-type-image d-flex">
              <img src={RightApple} className="apple-img mt-1" alt="" />
              <img src={RightAndroid} className="mt-4" alt="" />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export { SocialMedia };
